import request from '@/utils/request';

export function schoolList(query) {
    return request({
        url: 'bumen/queryBumen',
        method: 'get',
        params: query
    })
}

export function queryBumenById(schoolId) {
    return request({
        url: 'bumen/queryBumenById',
        method: 'get',
        params: schoolId
    })
}

export function addSchool(bumen) {
    return request({
        url: 'bumen/addBumen',
        method: 'post',
        data: bumen
    })
}

export function addBumenList(bumen) {
    return request({
        url: 'bumen/addBumenList',
        method: 'post',
        data: bumen
    })
}

export function updateBymenById(bumen) {
    return request({
        url: 'bumen/updateBymenById',
        method: 'put',
        data: bumen
    })
}

export function deleteByBumenId(bumenId) {
    return request({
        url: 'bumen/deleteByBumenId',
        method: 'delete',
        data: bumenId
    })
}

