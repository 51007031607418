<template>
  <div class="app-container menus">
    <el-form
      :model="queryParams"
      ref="queryForm"
      v-hasPermi="['system:file:list']"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
      style="width: 100%; height: 0.5%; text-align: left"
    >
      <el-form-item label="用户名称" prop="realName">
        <el-input
          v-model="queryParams.realName"
          placeholder="请输入用户名称"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select
          v-model="queryParams.type"
          placeholder="文件类型"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in fileTypes"
            :key="dict.id"
            :label="dict.value"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          v-hasPermi="['system:file:list']"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-refresh"
          size="mini"
          @click="resetQuery"
          v-hasPermi="['system:file:list']"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      ref="tables"
      :data="list"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <el-table-column label="编号" align="center" prop="id" />
      <el-table-column
        label="用户名称"
        align="center"
        prop="createBy"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="文件名称"
        align="center"
        prop="fileName"
        :show-overflow-tooltip="true"
      />
      <el-table-column label="类型" align="center" prop="status">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === '1'">课件</el-tag>
          <el-tag type="success" v-else-if="scope.row.type === '2'"
            >教案</el-tag
          >
          <el-tag type="danger" v-else>总结</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="上传时间"
        align="center"
        prop="createTime"
        width="180"
      >
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-link
            v-hasPermi="['system:file:exp']"
            type="success"
            :href="scope.row.fileUrl"
            target="_blank"
            :underline="false"
            style="margin-right: 10px"
          >
            <el-button type="text" icon="el-icon-download">下载</el-button>
          </el-link>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:file:delete']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import request from "@/utils/request.js";
import { queryFileList, deleteFile } from "@/api/user/file";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { schoolList } from "@/api/user/shcool";
import { download } from "@/utils/request";

export default {
  name: "fileManage",
  components: { Treeselect },
  data() {
    return {
      // 总条数
      total: 0,
      loading: false,
      ids: [],
      // 表格数据
      list: [],
      // 弹窗状态
      open: false,
      // 学校树选项
      schoolOptions: [],
      // 搜索学校树
      querySchool: [],
      // 搜索框是否显示
      showSearch: true,
      // 非多个禁用
      multiple: true,
      // 查询参数
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      fileTypes: [
        { value: "课件", id: 1 },
        { value: "教案", id: 2 },
        { value: "总结", id: 3 },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      queryFileList(this.queryParams).then((res) => {
        this.list = res.data.ossfiles;
        this.total = res.data.pageBean.count;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.queryParams.pageSize = 10;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框
    handleSelectionChange(val) {
      this.ids = val.map((item) => item.id);
      this.multiple = !val.length;
    },
    handleDelete(row) {
      const id = row.id || this.ids;
      this.$confirm('是否确认删除编号为"' + id + '"的数据项？', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteFile({ id }).then((res) => {
            if (res.status === 200) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.app-container {
  padding: 20px;
}
.mb8 {
  margin: 0 -5px 8px;
}
.treeselect-main {
  width: 280px;
  margin-top: 5px;
  line-height: 30px;
}
.vue-treeselect >>> .vue-treeselect__placeholder {
  line-height: 30px;
}
.vue-treeselect >>> .vue-treeselect__control {
  height: 30px !important;
}
.vue-treeselect >>> .vue-treeselect__menu {
  overflow-x: auto !important;
}
.vue-treeselect >>> .vue-treeselect__label {
  overflow: unset;
  text-overflow: unset;
}
</style>
