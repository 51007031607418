import request from "@/utils/request";
import qs from "qs";

export function queryFileList(data) {
  return request({
    url: "ossfile/selectFileList",
    method: "get",
    params: data,
  });
}

export function deleteFile(data) {
  return request({
    url: "ossfile/deleteUserFile",
    method: "post",
    data,
  });
}

export function selectUserMarkInfo(data) {
  return request({
    url: "userMarkInfo/selectUserMarkInfo",
    method: "get",
    params: data,
  });
}
